.leaders {
	display: grid;
	grid-template-columns: auto auto auto;
	grid-template-rows: repeat(10, 1fr);
	grid-column-gap: 32px;
	grid-row-gap: 4px;
	width: 100%;
	font-weight: bold;
}

.leader-rank {
	text-align: left;
}

.leader-tag {
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.leader-tag > :first-child {
	display: inline-flex;
	margin-right: 4px;
}

.leader-score {
	text-align: right;
}

@media screen and (max-width: 520px) {
	.leader-tag > :first-child {
		transform: translateY(-2px);
	}
}