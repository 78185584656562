.progress-bar {
	position: relative;
	width: 100%;
	height: 36px;
	background-color: var(--dark-color);
	border-radius: var(--border-radius);
	color: beige;
	font-weight: bold;
	font-size: 14px;
	overflow: hidden;
	margin: 2px;
}

.progress-fill {
	width: 0%;
	height: 100%;
	background-color: rgb(255, 200, 0);
	background-image: repeating-linear-gradient(45deg, transparent, transparent 20px, rgba(255,255,255,.4) 20px 40px);
	background-size: 2048px 100%;
	animation: pan 100s linear 0s infinite;
	transition: all 1s ease-in-out;
}

@keyframes pan {
	0% {
		background-position-x: 256px;
	}
	100% {
		background-position-x: 2048px;
	}
}

#progress-txt {
	position: absolute;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}

#progress-txt > div {
	background-color: var(--dark-color);
	border-radius: var(--border-radius);
	padding: 0px 4px;
	white-space: pre-wrap;
}

#progress-txt > span {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 4px;
}