@font-face {
	font-family: 'SourceSansPro';
	font-weight: bold;
	font-display: swap;
	src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url('https://points4words.io/fonts/SourceSansPro-SemiBold.woff2') format('woff2')
}

@font-face {
	font-family: 'SourceSansPro';
	font-weight: normal;
	font-display: swap;
	src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url('https://points4words.io/fonts/SourceSansPro-Regular.woff2') format('woff2')
}

html,
body,
#root {
	color: beige;
	background-color: var(--cell-color);
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	user-select: none;
	touch-action: none;
	margin: 0;
	width: 100%;
	height: 100%;
}

:root {
	--theme-color: #6e4d8a;
	--cell-color: #49315e;
	--light-color: #8d61b4;
	--dark-color: #3c2b4d;
	--clear-color: #49315e66;
	--border-radius: 5px;
	--tile-border-radius: 15%;
	--tile-size: 70px;
	--board-size: 68vh;
}

::placeholder {
	color: var(--theme-color);
	font-weight: bold;
}

::selection {
	color: violet;
	background: darkviolet;
}

*:focus {
	outline: transparent none 0px;
}

h1 {
	font-size: 48px;
	margin: 16px 0px;
}

h2 {
	margin: 0px 0px 8px 0px;
}

h3 {
	margin: 8px 0px 12px 0px;
}

h4 {
	color: gold;
	font-size: 24px;
	margin: 0px;
}

p {
	font-size: 16px;
	margin: 0px 0px 8px 28px;
}

a {
	color: var(--light-color);
}

button {
	color: beige;
	background-color: darkgrey;
	border-color: grey;
	margin: 8px;
	padding: 16px;
	border-style: solid;
	border-width: 0 0 3px 0;
	border-radius: var(--border-radius);
	font-size: 16px;
	font-weight: bold;
	transition: all 0.05s;
	cursor: pointer;
	flex-shrink: 0;
}

button:hover {
	transform: scale(1.1);
}

button:active {
	transform: scale(0.95);
}

button:disabled {
	opacity: 0.25;
	pointer-events: none;
}

.primary-input {
	color: beige;
	caret-color: beige;
	background-color: var(--dark-color);
	border-style: solid;
	border-width: 4px;
	border-radius: var(--border-radius);
	border-color: violet;
	margin: 8px;
	padding: 16px;
	font-size: 16px;
	font-weight: bold;
}

.icon-btn {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	width: 53px;
	height: 53px;
	padding: 0;
	font-weight: normal;
}

.primary-btn {
	background-color: var(--light-color);
	border-color: var(--theme-color);
}

.secondary-btn {
	color: darkviolet;
	background-color: violet;
	border-color: darkviolet;
}

.hero-btn {
	color: yellow;
	background-color: rgb(255, 200, 0);
	border-color: yellow;
}

.hero-btn:hover {
	color: rgb(255, 200, 0);
	background-color: yellow;
	border-color: rgb(255, 200, 0);
}

.bounce-btn {
	transition: all 0.1s;
	transform: translateY(0%);
}

.bounce-btn:hover {
	transform: translateY(-8px);
}

.bounce-btn:active {
	transform: translateY(0%);
}

.info-tag {
	color: var(--dark-color);
	background-color: orange;
	border-radius: 12px;
	padding: 4px;
	font-weight: bold;
	font-size: 12px;
}

.center-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	flex-grow: 0.5;
}

.card {
	display: flex;
	flex-direction: column;
	width: 230px;
	padding: 8px;
	margin: 0px 8px;
	color: beige;
	background-color: var(--clear-color);
	border-radius: var(--border-radius);
	text-align: center;
}

.modal {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	top: 50%;
	left: 50%;
	padding: 16px;
	border-radius: 15px;
	text-align: center;
	border-style: solid;
	border-width: 8px;
	border-color: var(--theme-color);
	background-color: var(--cell-color);
	transform: translate(-50%, -50%);
	white-space: pre-wrap;
}

.modal-hidden {
	visibility: hidden;
}

.ReactModalPortal .ReactModal__Overlay{
	transition: opacity 100ms ease-in-out;
	opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
	opacity: 0;
}

.overlay {
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	background-color: rgb(0, 0, 0, 0.75);
	z-index: 9999;
}

.modal-swap {
	transform: unset;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto auto;
	width: calc(var(--tile-size) * 5.5);
	height: calc(var(--tile-size) + 150px);
}

.modal-hero {
	transform: unset;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto auto;
	width: 256px;
	height: 180px;
	border-color: gold;
}

.modal-tall {
	height: 230px;
}

.modal-loss {
	border-color: var(--theme-color);
}

.outer-star-anim {
	animation-delay: 0s;
}

.inner-star-anim {
	animation-delay: 0.1s;
}

.overlay-swap {
	z-index: 4;
}

.scroll-thumb {
	background-color: violet;
	border-radius: 32px;
	cursor: pointer;
}

.scroll-track {
	right: 0%;
	height: 100%;
	background-color: var(--cell-color);
	border-radius: 32px;
}

.notification-dot:after {
	position: absolute;
	width: 16px;
	height: 16px;
	background-color: gold;
	border-radius: 50%;
	content: "";
	transform: translate(100%, -100%) scale(0);
	animation: pop 0.5s ease-in-out 1s 1 forwards;
}

.burger-dot:after {
	top: 4px;
	right: 4px;
}

.stat {
	display: inline-flex;
	justify-content: space-between;
}
.stat > :last-child {
	color: violet;
	font-weight: bold;
}

#sponsor-hero {
	margin: 8px auto;
	margin-right: auto;
	padding: 8px;
	background-color: var(--clear-color);
	border-radius: 8px;
	color: gold;
}

@keyframes pop {
	0% { transform: translate(100%, -100%) scale(0); }
	75% { transform: translate(100%, -100%) scale(1.5); }
	100% { transform: translate(100%, -100%) scale(1); }
}

@media (hover: none) {
	button:hover {
		transform: scale(1);
	}
	
	button:active {
		transform: scale(0.95);
	}

	.bounce-btn:hover {
		transform: translateY(0%);
	}

	.bounce-btn:active {
		transform: scale(0.95);
	}
}

@media screen and (max-width: 520px) {
	:root {
		--tile-size: calc((99vw - 28px) / 7);
	}

	h1 {
		font-size: 36px;
	}

	.modal-wide {
		width: 70%;
	}
}

@media screen and (max-height: 600px) and (min-width: 520px) {
	:root {
		--tile-size: 55px;
		--board-size: 70vh;
	}
}