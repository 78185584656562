.quest-tracker {
	position: absolute;
	background-color: var(--cell-color);
	border-radius: var(--border-radius);
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	width: 256px;
	padding: 8px;
	text-align: center;
	z-index: 999999;
	box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.5);
}

.quest-tracker > :last-child {
	width: calc(100% - 3px);
}

.quest-tracker-hidden {
	visibility: hidden;
}
