#app {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: var(--theme-color);
	overflow: hidden;
	width: 100%;
	height: 100%;
}

#scroll-bg {
	position: absolute;
	top: 0%;
	left: 0%;
	--bg-size: 125vh;
	width: calc(var(--bg-size) * 4);
	height: calc(var(--bg-size) * 4);
	background-position: 25% 25%;
	background-repeat: repeat;
	background-size: var(--bg-size) var(--bg-size);
	animation: slide 120s linear infinite;
	pointer-events: none;
}