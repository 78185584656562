#sidemenu-wrapper {
	position: absolute;
	left: 0%;
}

#buttons-wrapper {
	display: flex !important;
	align-items: center;
	justify-content: space-between;
	width: calc(100% - 16px);
	background-color: var(--cell-color);
	border-radius: var(--border-radius);
	padding: 0px;
}

#buttons-wrapper > button {
	margin: 0px;
}

/* Position and sizing of burger button */
.bm-burger-button {
	display: block;
	position: fixed;
	width: 42px;
	height: 42px;
	bottom: calc(var(--tile-size) + 5%);
	left: 0%;
	background-color: var(--cell-color);
	border-color: var(--cell-color);
	border-style: solid;
	border-radius: 0 var(--border-radius) var(--border-radius) 0;
	border-width: 6px;
	box-sizing: border-box;
	z-index: 1 !important;
}

.bm-back-button {
	z-index: 9999 !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
	background: violet;
	border-radius: var(--border-radius);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
	background: violet;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
	position: fixed;
	height: 100%;
}

/* General sidebar styles */
.bm-menu {
	background: var(--dark-color);
	overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
	fill: var(--dark-color);
}

/* Wrapper for item list */
.bm-item-list {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.bm-item-list > div {
	box-sizing: border-box;
	width: 100%;
}

.bm-item-list > :first-child {
	width: calc(100% - 16px);
	background-color: var(--cell-color);
	margin-top: 8px;
}

.bm-item-list > :last-child {
	margin-bottom: 8px;
}

.bm-item-list > #games-card {
	background-color: transparent;
	padding-right: 0px;
}

/* Styling of overlay */
.bm-overlay {
	background: rgba(0, 0, 0, 0.75) !important;
}