#home {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	flex-grow: 1;
	overflow: hidden;
}

#title-wrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 0.5;
	width: 100%;
}

#header {
	position: relative;
}

#header-logo {
	height: 12vh;
}

#wip-tag {
	position: absolute;
	top: 10%;
	left: 59.5%;
	transform: rotate(16deg);
	transform-origin: left;
	width: 100px;
	text-align: center;
	cursor: pointer;
}

#menu {
	flex-grow: 0.5;
}

#cards {
	display: flex;
}

#home-profile-card {
	display: flex;
}

#home-games-card {
	display: flex;
}

#settings-btn {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 40px;
	height: 40px;
}

#twitter-btn {
	position: absolute;
	bottom: 0;
	right: 48px;
	background-color: #40A2F5;
	border-color: #2b6ea8;
	width: 40px;
	height: 40px;
}

#host-btn {
	position: absolute;
	bottom: 0;
	right: 96px;
	background-color: #6738BC;
	border-color: #4C2F97;
	width: 40px;
	height: 40px;
}

#host2-btn {
	position: absolute;
	bottom: 0;
	right: 96px;
	background-color: #21232A;
	border-color: #21232A;
	width: 40px;
	height: 40px;
}

#play-controls {
	display: flex;
	justify-content: space-between;
}

#meta-controls {
	display: flex;
	justify-content: space-between;
}

@keyframes slide {
	0% { transform: translate(0, 0); }
	100% { transform: translate(-50%, -50%);}
}

@media screen and (max-width: 768px) {
	#header-logo {
		height: 14vh;
	}

	#home-profile-card {
		display: none;
	}

	#home-games-card {
		display: none;
	}

	#settings-btn {
		display: none;
	}

	#twitter-btn {
		position: absolute;
		bottom: 0;
		right: 0;
	}

	#host-btn {
		display: none;
	}
}
