#footer {
	position: relative;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	width: 100%;
	height: 5%;
	margin-top: auto;
	background-color: var(--cell-color);
	box-sizing: content-box;
	padding: 8px;
	flex-grow: 0;
	flex-shrink: 1;
}

#footer-links {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding-right: 8px;
}

#footer-links a {
	margin-left: 16px;
}

#version-label {
	color: var(--theme-color);
	margin-right: auto;
	padding-left: 8px;
}

@media screen and (max-width: 520px) {
	#footer {
		font-size: 14px;
	}
}