.room-item {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	font-weight: bold;
	color: violet;
	background-color: var(--cell-color);
	border-style: solid;
	border-color: violet;
	border-width: 0 0 0px 0;
	border-radius: var(--border-radius);
	padding: 8px;
	margin-bottom: 8px;
}

.room-name {
	flex-grow: 1;
	font-style: italic;
	white-space: pre-wrap;
}

.party-room {
	font-size: 90%;
}

.room-item > button {
	margin: 0px;
	width: 40px;
	height: 40px;
}