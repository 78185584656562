#profile-card {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-self: center;
}

#profile-pic {
	width: 96px;
	border-radius: var(--border-radius);
}

#profile-stats {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	margin: 8px;
	width: 95%;
}

#profile-signin {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

#stats-btn {
	position: absolute;
	left: 6px;
	bottom: 4px;
	width: 40px;
	height: 40px;
}

.g-btn {
	background-color: #E1412F !important;
	border-radius: var(--border-radius) !important;
	margin: 0px;
	transform: scale(0.75);
}

.g-btn:hover {
	transform: scale(0.9);
}

.g-btn > :last-child {
	font-size: 16px;
	font-weight: bold !important;
}